$icon-font-path: '/assets/fonts/';
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/css/font-awesome.min.css);
$font-family-sans-serif: 'Lato', sans-serif;
@import 'vendor/bower_components/bootstrap-sass/assets/stylesheets/bootstrap';



textarea.expandable {
  height: 52px;
  transition: height 120ms ease;

  &:focus {
    height: 104px;
  }
}

select.form-control + .chosen-container.chosen-container-single .chosen-single {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  background-image:none;
}

select.form-control + .chosen-container.chosen-container-single .chosen-single div {
  top:4px;
  color:#000;
}

select.form-control + .chosen-container .chosen-drop {
  background-color: #FFF;
  border: 1px solid #CCC;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  margin: 2px 0 0;

}

select.form-control + .chosen-container .chosen-search input[type=text] {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #FFF;
  border: 1px solid #CCC;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  background-image:none;
}

select.form-control + .chosen-container .chosen-results {
  margin: 2px 0 0;
  padding: 5px 0;
  font-size: 14px;
  list-style: none;
  background-color: #fff;
  margin-bottom: 5px;
}

select.form-control + .chosen-container .chosen-results li ,
select.form-control + .chosen-container .chosen-results li.active-result {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333;
  white-space: nowrap;
  background-image:none;
}
select.form-control + .chosen-container .chosen-results li:hover,
select.form-control + .chosen-container .chosen-results li.active-result:hover,
select.form-control + .chosen-container .chosen-results li.highlighted
{
  color: #FFF;
  text-decoration: none;
  background-color: #428BCA;
  background-image:none;
}

select.form-control + .chosen-container-multi .chosen-choices {
  display: block;
  width: 100%;
  min-height: 34px;
  padding: 6px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #FFF;
  border: 1px solid #CCC;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  background-image:none;
}

select.form-control + .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  height:auto;
  padding:5px 0;
}

select.form-control + .chosen-container-multi .chosen-choices li.search-choice {

  background-image: none;
  padding: 3px 24px 3px 5px;
  margin: 0 6px 0 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  background-color: #FFF;
  border-color: #CCC;
}

select.form-control + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  top:8px;
  right:6px;
}

select.form-control + .chosen-container-multi.chosen-container-active .chosen-choices,
select.form-control + .chosen-container.chosen-container-single.chosen-container-active .chosen-single,
select.form-control + .chosen-container .chosen-search input[type=text]:focus{
  border-color: #66AFE9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),0 0 8px rgba(102, 175, 233, 0.6);
}

select.form-control + .chosen-container-multi .chosen-results li.result-selected{
  display: list-item;
  color: #ccc;
  cursor: default;
  background-color: white;
}






/* Show load indicator when image is being loaded */
.cropit-preview.cropit-image-loading .spinner {
  opacity: 1;
}

/* Show move cursor when image has been loaded */
.cropit-preview.cropit-image-loaded .cropit-preview-image-container {
  cursor: move;
}

.cropit-preview-image-container {
  background: url(image-placeholder.png) no-repeat center center;
  background-size: cover;
}

/* Gray out zoom slider when the image cannot be zoomed */
.cropit-image-zoom-input[disabled] {
  opacity: .2;
}

/* The following styles are only relevant to when background image is enabled */

/* Translucent background image */
.cropit-preview-background {
  opacity: .2;
}

/* Limit the background image by adding overflow: hidden */
#image-cropper {
  overflow: hidden;
}

/* ImagePreview */
.image-preview {
  width: 100%;
  height: 120px;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  color: #ecf0f1;
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;

  &.with-image {
    height: 360px;
  }

  input {
    line-height: 200px;
    font-size: 200px;
    position: absolute;
    opacity: 0;
    z-index: 10;
  }

  label {
    position: absolute;
    z-index: 5;
    opacity: 0.8;
    cursor: pointer;
    background-color: #bdc3c7;
    width: 200px;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-transform: uppercase;
    border-radius: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
  }
}

.list-group .child {
  &:before {
    content: '\21B3';
    padding-right: 5px;
  }
}